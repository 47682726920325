(<template>
  <article :class="{'in-progress': progressActive}"
           class="one-employee is-progress-bar">
    <p :class="{'one-employee__invitation-status--yellow': !acceptedInvite}"
       class="one-employee__invitation-status">{{ invStatusText }}</p>
    <div class="one-employee__user">
      <div class="one-employee__info-wrapper">
        <div class="one-employee__info-cont">
          <p v-if="name"
             class="one-employee__info-row">{{ name }}</p>
          <p v-if="email"
             class="one-employee__info-row">{{ email }}</p>
          <a v-if="phone"
             :href="`tel:${phone}`"
             class="one-employee__info-row one-employee-tel js-copy-to-clipboard">{{ phone }}</a>
          <button v-else-if="allowEdit"
                  class="one-employee__control-btn"
                  @click="editEmployee">{{ btnAddPhoneText }}</button>
          <p v-if="buyerRef"
             class="one-employee__info-row"
             v-html="buyerRefCont">
          </p>
          <button v-else-if="allowEdit"
                  class="one-employee__control-btn"
                  @click="editEmployee">{{ btnAddBuyerText }}</button>
          <p v-if="orderRef"
             class="one-employee__info-row"
             v-html="orderRefCont"></p>
          <button v-else-if="allowEdit"
                  class="one-employee__control-btn"
                  @click="editEmployee">{{ btnAddOrderText }}</button>
        </div>
        <button v-if="allowEdit"
                class="one-employee__control-btn btn-min-content"
                @click="editEmployee">{{ $gettext('Edit') }}</button>
      </div>
      <div class="one-employee__departments-wrapper">
        <div :class="{'one-employee__departments-cont--full': showAllDepartments}"
             class="one-employee__departments-cont">
          <p v-for="department in departments"
             :key="department.id"
             class="one-employee__department">{{ department.name || '' }}</p>
        </div>
        <button v-if="showMoreDepBtn"
                class="one-employee__control-btn"
                @click="showAllDepartments = !showAllDepartments">{{ showDepartmentsText }}</button>
      </div>
      <div class="one-employee__togglers-wrapper">
        <div class="one-employee__toggler-cont">
          <p class="one-employee__toggler-name">{{ $gettext('Admin') }}</p>
          <sk-toggler :preselected-value="isAdmin"
                      :item-value="'admin'"
                      :disabled="!userIsAdminOfEnterprise"
                      @togglerchanged="catchToggler" />
        </div>
        <div class="one-employee__toggler-cont">
          <p class="one-employee__toggler-name">{{ $gettext('Manager') }}</p>
          <sk-toggler :preselected-value="isFinance"
                      :item-value="'finance'"
                      :disabled="!canEdit"
                      @togglerchanged="catchToggler" />
        </div>
        <div class="one-employee__toggler-cont">
          <p class="one-employee__toggler-name">{{ $gettext('Coordinator') }}</p>
          <sk-toggler :preselected-value="isOrganizer"
                      :item-value="'organizer'"
                      :disabled="!userIsBooker && !canEdit"
                      @togglerchanged="catchToggler" />
        </div>
        <!--        <div class="one-employee__toggler-cont">-->
        <!--          <p class="one-employee__toggler-name">{{ $gettext('Attender') }}</p>-->
        <!--          <sk-toggler :preselected-value="isAttender"-->
        <!--                      :item-value="'attender'"-->
        <!--                      @togglerchanged="catchToggler" />-->
        <!--        </div>-->
      </div>
      <div class="one-employee__remove-btn-cont">
        <button v-if="allowEdit"
                :title="removeText"
                class="one-employee__remove-btn"
                @click="confirmEmployeeRemoval"></button>
      </div>
    </div>
    <button v-if="!acceptedInvite && canEdit"
            class="one-employee__control-btn one-employee__invite"
            @click="resendInvitation">{{ $gettext('Resend invitation email') }}</button>
  </article>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    props: {
      user: {
        type: Object,
        default() {
          return {};
        }
      },
      isCurrentEmployee: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        depVisibleMin: 3,
        progressActive: false,
        showAllDepartments: false
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userUid',
        'userIsAdminOfEnterprise',
        'userIsFinanceManager',
        'userIsBooker'
      ]),
      name() { return this.user.firstName && this.user.lastName ? `${this.user.firstName} ${this.user.lastName}` : ''; },
      email() { return this.user.email; },
      buyerRef() { return this.user.bookingReference; },
      orderRef() { return this.user.paymentBookingReference; },
      buyerRefCont() {
        const buyerRefName = this.$store.getters['UserInfoStore/labelForBookingRef'] || this.$gettext('Buyer reference');
        return `<strong>${buyerRefName}:</strong> ${this.buyerRef}`;
      },
      orderRefCont() {
        const orderRefName = this.$store.getters['UserInfoStore/labelForPaymentBookingRef'] || this.$gettext('Order reference');
        return `<strong>${orderRefName}:</strong> ${this.orderRef}`;
      },
      phone() {
        return this.user.phoneCode && this.user.phoneNumber ? `${this.user.phoneCode}${this.user.phoneNumber}` : '';
      },
      departments() { return this.user.departments || []; },
      removeText() { return this.$gettext('Remove'); },
      loginText() { return this.$gettextInterpolate('Login as %{ f }', {f: this.user.firstName}); },
      btnAddPhoneText() { return this.$gettext('Add phone number'); },
      btnAddBuyerText() { return this.$gettext('Add buyer reference'); },
      btnAddOrderText() { return this.$gettext('Add order reference'); },
      canEdit() { return this.userIsAdminOfEnterprise || this.userIsFinanceManager; },
      acceptedInvite() { return this.user.status == 'accepted'; },
      invStatusText() { return this.acceptedInvite ? this.$gettext('Confirmed') : this.$gettext('Waiting'); },
      showMoreDepBtn() { return this.departments.length > this.depVisibleMin; },
      showDepartmentsText() {
        if (!this.showAllDepartments) {
          return this.$gettextInterpolate('+ %{n} more', {n: this.departments.length - this.depVisibleMin});
        } else {
          return this.$gettext('Show less');
        }
      },
      isAdmin() {
        return this.user.roles && this.user.roles.length && this.user.roles.indexOf('admin') != -1;
      },
      isFinance() {
        return this.user.roles && this.user.roles.length && this.user.roles.indexOf('finance') != -1;
      },
      isOrganizer() {
        return this.user.roles && this.user.roles.length && this.user.roles.indexOf('organizer') != -1;
      },
      isAttender() {
        return this.user.roles && this.user.roles.length && this.user.roles.indexOf('attender') != -1;
      },
      allowEdit() { return this.user.uid === this.userUid || this.userIsAdminOfEnterprise; }
    },
    methods: {
      catchToggler(value, name) {
        this.$emit('changerights', value, name, this.user.uid);
      },
      removeItem() {
        this.$emit('removemember', this.user.uid);
      },
      confirmEmployeeRemoval() {
        if (this.canEdit) {
          this.$store.commit('ModalStore/setModal', {
            component: 'remove-confirm',
            classes: ['remove-confirm'],
            width: 410,
            data: {
              title: this.$gettext('Delete employee?'),
              text: this.$gettext('Are you sure you want to delete the employee from the company account?'),
              btnTexts: {
                removeBtnText: this.$gettext('Delete'),
                cancelBtnText: this.$gettext('Cancel')
              },
              context: this
            }
          });
        }
      },
      editEmployee() {
        this.$store.commit('ModalStore/setModal', {
          component: 'add-employee',
          width: 410,
          data: {
            title: this.$gettext('Edit employee'),
            currentUser: this.user,
            hideRoleToggles: true,
            context: this,
            successHandlingCallbackName: this.isCurrentEmployee ? 'getCurrentEmployee' : null
          }
        });
      },
      resendInvitation() {
        this.progressActive = true;
        this.$store.dispatch('EnterpriseStore/resendInvite', this.user.uid).then(() => {
          this.progressActive = false;
        }).catch(() => {
          this.progressActive = false;
        });
      },
      getCurrentEmployee() {
        this.$store.dispatch('EnterpriseStore/getCurrentEmployee');
      }
    }
  };
</script>

<style scoped>
  .one-employee {
    position: relative;
    padding: 20px;
    border-top: 1px solid #d3d5de;
    font-size: 12px;
  }

  .one-employee__invitation-status {
    display: block;
    width: 100%;
    margin-bottom: 3px;
    color: #000;
    text-transform: uppercase;
  }

  .one-employee-tel {
    width: auto;
    color: #000;
    font-weight: bold;
    text-decoration: underline;
  }

  .one-employee-tel:hover {
    color: var(--color-secondary);
  }

  .one-employee__invitation-status--yellow {
    color: #f93;
  }

  .one-employee__user {
    display: flex;
  }

  .one-employee__info-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .one-employee__info-cont {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
  }

  .one-employee__info-row {
    display: block;
    overflow: hidden;
    width: 100%;
    padding-bottom: 2px;
    text-overflow: ellipsis;
  }

  .one-employee__departments-wrapper {
    width: 100%;
    max-width: 260px;
    padding-right: 10px;
  }

  .one-employee__departments-cont {
    overflow: hidden;
    height: 60px;
  }

  .one-employee__departments-cont--full {
    height: auto;
  }

  .one-employee__department {
    overflow: hidden;
    padding-bottom: 2px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .one-employee__togglers-wrapper {
    position: relative;
    display: flex;
    width: 260px;
  }

  .one-employee__toggler-cont {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 25%;
    min-width: 75px;
  }

  .one-employee__toggler-name {
    display: none;
    overflow: hidden;
    width: 100%;
    margin-bottom: 5px;
    margin-left: 20px;
    font-weight: bold;
    font-size: 10px;
    text-overflow: ellipsis;
  }

  .one-employee__remove-btn-cont {
    flex-shrink: 0;
    width: 24px;
    text-align: right;
  }

  .one-employee__remove-btn {
    display: block;
    width: 24px;
    height: 24px;
    margin-top: 10px;
    background-image: url(~@assets/imgs/undefined_imgs/trash_ico_black.svg);
    background-position: 50% 50%;
    background-size: 15px auto;
    background-repeat: no-repeat;
  }

  .one-employee__remove-btn:focus {
    outline: var(--default-button-focus-outline);
  }

  .one-employee__remove-btn:first-child {
    margin: 0;
  }

  .one-employee__login-as-btn {
    display: block;
    width: 24px;
    height: 24px;
    background-image: url(~@assets/imgs/nav_icons/header_icon_password.svg);
    background-position: 50% 50%;
    background-size: 17px auto;
    background-repeat: no-repeat;
    cursor: default;
  }

  .one-employee__control-btn {
    color: #ff5b24;
    color: var(--color-sk-link);
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }

  .one-employee__control-btn:focus {
    outline: var(--default-button-focus-outline);
  }

  .btn-min-content {
    width: min-content;
    padding-top: 5px;
  }

  .one-employee__invite {
    padding-top: 10px;
    color: #f93;
  }

  @media (max-width: 1024px) {
    .one-employee {
      padding: 15px;
    }

    .one-employee__user {
      flex-direction: column;
    }

    .one-employee__info-wrapper {
      margin-bottom: 15px;
    }

    .one-employee__info-cont {
      width: 100%;
    }

    .one-employee__departments-wrapper {
      max-width: initial;
      margin-bottom: 15px;
      padding: 0;
    }

    .one-employee__togglers-wrapper {
      width: 100%;
    }

    .one-employee__toggler-name {
      display: block;
      margin-left: 0;
    }

    .one-employee__remove-btn-cont {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      justify-content: flex-end;
      width: 60px;
      margin: 15px;
    }

    .one-employee__remove-btn {
      margin: 0 0 0 15px;
    }

    .one-employee__departments-cont {
      overflow: auto;
      height: auto;
    }
  }
</style>
